<template>
    <div >


        <CSDialog
                :dialogTitle="addTenantTitle"
                :dialogVisible="editCompanyOwnerVisible"
                :dialogWidth="650"
                class="oppositeName contract-modal editOver-container"
                 @onClose=" editCompanyOwnerVisible = false;
                isEdit = false;
                leaseSave = {};"
                @onConfirm="addCompanyOwner"
                @click="dialogBtn"
        >
            <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px"  @click="dialogBtn">
                    <div style="margin-bottom: 0px" class="wrapper-edit">
                        <label style="width: 130px; margin:0 20px 0 0; text-align: right">
                            选择租客
                        </label>
                        <CSRadio v-model="searchCompanyOwner" :items="{1: '企业', 2: '个人'}" @input="selectInput" />
                    </div>

            </div>
            <div slot="dialog-content" class="room-form" @click="dialogBtn" >
                <div class="field-item">
                    <label
                            class="field-item-label"
                            style="width: 130px; margin-right: 50px"
                    ></label
                    >
                    <div class="field-item-content" style="position: relative">
                        <CSSelect height="40px" iWidth="36px" style="width: 300px">
                            <DropDownSelection
                                    @queryInfo="queryInfo"
                                    @selectWarte="selectValue"
                                    :dataList="modifyLeaseList"
                                    :item="leaseItem"
                                    :placeholderName="searchInfo"
                                    type="tenant"
                                    :selectInputKey = 'selectInputKey'
                                    :isDisable = 'addTenantTitle!="修改租客"'
                            ></DropDownSelection>
                        </CSSelect>
                    </div>
                </div>
            </div>
            <div slot="dialog-content" style="padding: 1px 30px 20px 20px; font-size: 24px" >

                <div style="margin-bottom: 0px" class="wrapper-edit">
                    <label style="width: 130px; margin:0 20px 0 10px; text-align: right">
                        承租房间
                    </label>
                    <CSRadio v-model="relevanceType" :items="{2: '创建合同关联房间', 1: '直接关联房间'}" @input="selectInput" />
                </div>
                <div style="margin-bottom: 0px;margin-top: 20px;margin-left: 10px" class="wrapper-edit"  v-show="relevanceType == 1">
                    <label style="width: 130px; margin:0 20px 0 0; text-align: right">

                    </label>
<!--                    <RoomSelect-->
<!--                            class="leftFloat"-->
<!--                            :selected-rooms="roomIds"-->
<!--                            @change-rooms="changeContractRooms"-->
<!--                            :isTenant = 'true'-->
<!--                    ></RoomSelect>-->
                    <AllRoomSelectOwner
                            @change-rooms="changeContractRooms"
                            :selectedRooms="roomIds || []"
                            :isSelectedRooms="isSelectedRooms"
                            :checkedRoomNames="checkedRoomNames"
                            class="leftFloat"
                            :isUrl="true"
                    ></AllRoomSelectOwner>
                </div>

            </div>

        </CSDialog>
    </div>
</template>
<script>
    import {
        createTenantUrl,
        editTenantInfoUrl,
        searchEnterpriseIndividual,
        editTenantUrl,
        queryLesseeBindRoomsUrl
    } from "@/requestUrl";
    import CSSelect from "@/components/common/CSSelect";
    import CSDialog from "@/components/common/CSDialog";
    import CSRadio from "@/components/common/CSRadio";
    import DropDownSelection from "@/components/DropDownSelection.vue";
    import RoomSelect from "@/components/RoomSelect.vue";
    import AllRoomSelectOwner from "@/components/AllRoomSelectOwner";
    const OWNER_TYPE = {
        OWNER: "1001",
        TENANT: "1003",
    };

    const SEARCH_HINT = {
        1:"搜索企业名称/对接人",
        2:"搜索个人名称/对接人",
    }
    export default {
        props: {
            notifyLoadDataComponentName: String,
            componentTitle: String,
            ownerTypeCd: String,
        },
        components: {
            CSSelect,
            CSDialog,
            CSRadio,
            DropDownSelection,
            RoomSelect,
            AllRoomSelectOwner
        },
        data() {
            return {
                isSelectedRooms:[],
                checkedRoomNames:[],
                lesseeId:'',
                selectInputKey:'',
                addTenantTitle:'添加租客',
                rooms:[],
                roomIds:[],
                leaseItem: {}, //本条信息内容,用于回显
                leaseSave: {},
                modifyLeaseList:[], //企业列表
                step: 1,
                isEdit: false,
                editCompanyOwnerInfo: {
                    id: null,
                    regionId: this.$vc.getCurrentRegion().communityId,
                    companyName: "",
                    legalEntity: "",
                    businessLicense: "",
                    unifiedSocialCreditIdentifier: "",
                    businessAccount: "",
                    deputyName: "",
                    deputyIdNo: "",
                    deputyPhone: "",
                    deputyGender: "",
                    deputyEmail: "",
                    comment: "", //除了comment选填，上面都是必填
                },
                editCompanyOwnerVisible: false,
                addCompanyOwnerInfo: {
                    regionId: "",
                    type: 2,
                    id: ""
                },
                searchCompanyOwner: 1,
                relevanceType:2
            };
        },
        computed:{
            searchInfo(){
                return SEARCH_HINT[this.searchCompanyOwner];
            }
        },
        mounted() {
            this.queryInfo();
            this.$vc.on(
                this.$route.path,
                "EditCompanyTenant",
                "show",
                this.changeModalStatus
            );
        },
        methods: {
            //选择的房间变化
            changeContractRooms(res){

                const { roomIds, rooms } = res;
                console.log(rooms);
                this.rooms = rooms.map(item => {
                    return {
                        buildingName:item.buildingName,
                        buildingId:item.buildingId,
                        floor:item.floor,
                        roomId:item.roomId,
                        roomName:item.roomName
                    }
                })
            },
            selectInput(){
                 this.queryInfo();
            },
            //查询对接人
            queryInfo(search = "") {
                this.$fly
                    .post(searchEnterpriseIndividual, {
                        search,
                        type: this.searchCompanyOwner,
                        regionCode: this.$vc.getCurrentRegion().communityId,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.modifyLeaseList = res.data;
                    });
            },
            //选中的对接人
            selectValue(item) {
                console.log('选中的信息');
                console.log(item);
                this.leaseSave = item;
            },


            uploadEditCompanyPhoto() {
                $("#uploadEditCompanyOwnerPhoto").trigger("click");
            },
            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },
            chooseEditCompanyBusinessLicense(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    // 获取目前上传的文件
                    var file = photoFiles[0]; // 文件大小校验的动作
                    if (file.size > 1024 * 1024 * 2) {
                        this.$vc.toast("图片大小不能超过 2MB!");
                        return false;
                    }
                    var reader = new FileReader(); //新建FileReader对象
                    reader.readAsDataURL(file); //读取为base64
                    reader.onloadend = async (e) => {
                        this.editCompanyOwnerInfo.businessLicense = await this.uploadPhoto(
                            reader.result
                        ).catch((err) => {
                            console.error("上传营业执照失败");
                            return "";
                        });
                    };
                }
            },
            async changeModalStatus(tenant) {
                console.log('客户信息');
                console.log(tenant);
                this.editCompanyOwnerVisible = true;
                this.step = 1;
                if (tenant !== null) {
                    this.selectInputKey = `${tenant.companyName}-${tenant.deputyName}-${tenant.deputyPhone}`;
                    const bindRoom2 = await this.queryLesseeBindRooms(tenant.id);
                    bindRoom2.forEach((room) =>{
                        this.checkedRoomNames.push({
                            "buildingName":room.buildingName,
                            "roomName":room.roomName,
                        });
                    });
                    console.log(this.checkedRoomNames);
                    this.addTenantTitle = '修改租客'
                    this.isEdit = true;
                    this.searchCompanyOwner = tenant.type;
                    this.relevanceType= tenant.relevanceType;
                    this.lesseeId = tenant.id;
                    // console.log('tenant.roomIds');
                    // console.log(tenant.roomIds);
                    // this.roomIds = [];
                    this.roomIds = [...tenant.roomIds];
                    this.isSelectedRooms = tenant.roomIds || [];
                    this.checkedRoomNames = [];
                    this.leaseSave = {
                        deputyId:'',
                        deputyName:''
                    }
                    console.log(tenant);
                    return;
                }
                this.checkedRoomNames = [];
                this.isSelectedRooms = [];
                this.roomIds = [];
                this.rooms = [];
                this.selectInputKey = '';git
                this.searchCompanyOwner = 1;
                this.relevanceType= 1;
                this.addTenantTitle = '添加租客'
                this.resetCompanyEditOwnerInfo();
            },
            resetCompanyEditOwnerInfo() {
                this.editCompanyOwnerInfo = {
                    id: null,
                    regionId: this.$vc.getCurrentRegion().communityId,
                    companyName: "",
                    legalEntity: "",
                    businessLicense: "",
                    unifiedSocialCreditIdentifier: "",
                    businessAccount: "",
                    deputyName: "",
                    deputyIdNo: "",
                    deputyGender: "",
                    deputyEmail: "",
                    deputyPhone: "",
                    comment: "", //除了comment选填，上面都是必填
                    type: 2, //先写死
                };
            },
            createCompanyOwnerInfo() {
                const params = {...this.editCompanyOwnerInfo};
                delete params.id;
                this.$fly.post(createTenantUrl, params).then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    this.$vc.toast("添加成功");
                    this.editCompanyOwnerVisible = false;
                    this.$vc.emit(
                        this.$route.path,
                        this.notifyLoadDataComponentName,
                        "listCompanyOwnerData",
                        {}
                    );
                });
            },
            updateCompanyOwnerInfo() {
                delete this.editCompanyOwnerInfo.type;
                this.$fly
                    .post(editTenantInfoUrl, this.editCompanyOwnerInfo)
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.$vc.toast("修改成功");
                        this.editCompanyOwnerVisible = false;
                        this.$vc.emit(
                            this.$route.path,
                            this.notifyLoadDataComponentName,
                            "listCompanyOwnerData",
                            {}
                        );
                    });
            },
            editCompanyOwnerMethod() {
                if (
                    !this.editCompanyOwnerInfo.id ||
                    this.editCompanyOwnerInfo.id == null
                ) {
                    this.createCompanyOwnerInfo();
                } else {
                    this.updateCompanyOwnerInfo();
                }
            },
            dialogBtn() {
                console.log('关闭');
                this.$vc.emit("allRoomSelectContract", "selcet", {});
            },
            queryLesseeBindRooms(lesseeId){
                return new Promise((resolve) => {
                    this.$fly
                        .get(queryLesseeBindRoomsUrl, {
                            lesseeId,
                        })
                        .then((res) => {
                            resolve(res.data);
                        });
                });
            },
            /*新加 添加租客 */
            addCompanyOwner() {
                if(this.addTenantTitle == '修改租客'){
                    this.$fly.post(editTenantUrl,{
                        "lesseeId":this.lesseeId,  //企业id
                        "relevanceType":this.relevanceType,   //承租类型    1直接关联 2 通过合同关联
                        "rooms":this.rooms
                    }).then(res => {
                        if(res.code != 0){
                            return
                        }
                        this.$vc.toast("修改成功");
                        this.editCompanyOwnerVisible = false;
                        this.$vc.emit(
                            this.$route.path,
                            this.notifyLoadDataComponentName,
                            "listCompanyOwnerData",
                            {}
                        );
                    })
                }else{
                    this.$fly.post(createTenantUrl, {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        type: 2,
                        id: this.leaseSave.id,
                        relevanceType:this.relevanceType,
                        rooms:this.rooms
                    }).then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.$vc.toast("添加成功");
                        this.editCompanyOwnerVisible = false;
                        this.$vc.emit(
                            this.$route.path,
                            this.notifyLoadDataComponentName,
                            "listCompanyOwnerData",
                            {}
                        );
                    });
                }

            },
        },
    };
</script>
<style lang="stylus" scoped>
    .modal-body {
        input {
            &::placeholder {
                color: #999;
            }

            font-size: 24px;
            border-radius: 6px;
            height: 40px;
            box-sizing: border-box;
        }
    }

    .step-btn-wrap {
        .btn {
            min-width: 200px;
            min-height: 40px;
            padding: 0 12px;
            border-radius: 6px;
            font-size: 24px;

            &:last-of-type {
                margin-left: 60px;
            }
        }
    }

    .col-form-label {
        width: 195px;
        padding-left: 0;
        padding-right: 0;
        font-size: 24px;
        text-align: right;
        margin-right: 30px;
    }

    input, select {
        padding: 0 10px;
    }

    textarea {
        padding: 6px 10px;
    }

    input::-webkit-input-placeholder {
        color: #999;
    }

    input::-moz-placeholder {
        color: #999;
    }

    input::-moz-placeholder {
        color: #999;
    }

    input::-ms-input-placeholder {
        color: #999;
    }
    .room-form {
        // padding: 27px 30px;

        .field-item {
            margin-bottom: 22px;
            font-size: 0;

            &-label {
                font-size: 24px;
                text-align: right;
                width: 208px;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: 0;
            }

            &-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;

                input {
                    width: 400px;
                    height: 40px;
                    text-indent: 5px;
                    border-radius: 4px;
                    border: 1px solid #979797;

                    &::placeholder {
                        color: #999;
                    }
                }

                select {
                    width: 220px;
                    text-indent: 5px;
                }
            }
        }

        .tips {
            color: #999;
            font-size: 20px;
        }
    }
.wrapper-edit{
  display: flex;
  align-items: center;
}
</style>
